import { postAPICall } from "../helpers/httpHelper";

import { db } from "../utils/db";
import {
  paginationHelper,
  getPaginationResponse,
} from "../utils/paginationHelper";

import store from "@/store";

//delete formbuilder by id
export const deleteFormbuilder = async (groupId) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  const [selectedFormbuilder] = await db.formbuilders_details
    .where("_id")
    .equals(groupId)
    .primaryKeys();
  if (selectedFormbuilder) {
    await db.formbuilders_details.delete(selectedFormbuilder);
  }
};

//update single formbuilder
export const updateFormbuilderData = async (groupId, data) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  if(data?.id){
    delete data.id;
  }
  const [selectedFormbuilder] = await db.formbuilders_details
    .where("_id")
    .equals(groupId)
    .primaryKeys();
  if (selectedFormbuilder) {
    await db.formbuilders_details.delete(selectedFormbuilder);
    await addSingleFormbuilderToLocal(data);
  } else {
    await addSingleFormbuilderToLocal(data);
  }
};

//fetch formbuilder 
export const addSingleFormbuilderToLocal = async (groupData) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  return db.formbuilders_details.add({
    ...groupData,
    ...{ createdAt: new Date().toISOString() },
  });
};

export const fetchFormBuildersByPagination = async (params) => {
  let entities;
  entities = await fetchFormBuildersFromLocal(params);
  if (!entities) {
    entities = await fetchFormBuildersFromDatabase(params);
  }
  return entities;
};

export const fetchFormBuildersFromLocal = async (params) => {
  let collection = db.formbuilders_details;
  if (params.order_by) {
    collection = collection.orderBy(params.order_by);
    if (params.order_type === "desc") {
      collection = collection.reverse();
    }
  } else {
    collection = collection.orderBy("updated_at");
    collection = collection.reverse();
  }
  const regex = new RegExp(params.search_string || "", "i");

  if (
    params.existed_formBuilder_ids ||
    (params?.groups && params.groups.length) ||
    params?.group ||
    params.formBuilder_type ||
    !params.include_standard ||
    params.search_string
  ) {
    let groups = [
      ...(params.groups || []),
      ...(params.group ? [params.group] : []),
    ];
    collection = collection.filter((formBuilder) => {
      if (params?.existed_formBuilder_ids?.length) {
        return (
          params.existed_formBuilder_ids.includes(formBuilder._id) &&
          (!params.search_string || regex.test(formBuilder.name))
        );
      } else if (groups.length) {
        //need to change
        return (
          groups.some((e) => (formBuilder.groups || []).includes(e)) &&
          (!params.search_string || regex.test(formBuilder.name))
        );
      } else if (params.formBuilder_type) {
        return (
          formBuilder.type == params.formBuilder_type &&
          (!params.search_string || regex.test(formBuilder.name))
        );
      }
      return !params.search_string || regex.test(formBuilder.name);
    });
  }
  const count = await collection.count();
  if (params.limit) {
    params.skip = (params.page - 1) * params.limit;
  }
  collection = paginationHelper(collection, params);
  const results = await collection.toArray();
  if (!results || !results.length) {
    return null;
  }
  return getPaginationResponse({
    page: params.page || 1,
    count,
    limit: params.limit || 10,
    skip: params.skip,
    data: results,
    data_field: "data",
    message: "Templates fetched successfully.",
  });
};
export const fetchFormBuildersFromDatabase = async (params) => {
  return postAPICall("GET", `/formBuilders`, params);
};
